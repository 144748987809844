export interface OcktoSection {
  icon: string;
  title: string;
  description: string;
  id: number;
}

export interface CompanyInfo {
  name: string;
  description: string;
  imagePath: string;
  id: number;
}

export const OcktoSections: OcktoSection[] = [
  {
    icon: 'rabbit-running',
    title: 'Snel',
    description: 'In slechts vijf minuten doorloopt u de sessie in de Ockto app en levert u alle benodigde gegevens aan ons aan.',
    id: 0,
  },
  {
    icon: 'shield-check',
    title: 'Betrouwbaar',
    description: 'Ockto versleuteld uw gegevens en verstuurt deze via een beveiligde verbinding naar Mogelijk.',
    id: 1,
  },
  {
    icon: 'handshake',
    title: 'Veilig',
    description: 'De data wordt conform de hoogste veiligheidseisen en enkel met uw toestemming gedeeld.',
    id: 2,
  },
];

export const OcktoSteps: OcktoSection[] = [
  {
    icon: 'mobile-notch',
    title: 'Stap 1:  Scan de QR-code',
    description: 'Scan de QR-code. U kunt de Ockto-app downloaden of de app wordt geopend als u deze al heeft.',
    id: 0,
  },
  {
    icon: 'rectangle-history-circle-user',
    title: 'Stap 2: Haal gegevens op',
    description: 'U ziet in één oogopslag welke gegevens er beschikbaar zijn om te delen.',
    id: 1,
  },
  {
    icon: 'share-all',
    title: 'Stap 3: Gegevens delen',
    description: 'Na uw goedkeuring stuurt Ockto de benodigde informatie via een beveiligde omgeving naar Mogelijk.',
    id: 2,
  },
];

export const Companies: CompanyInfo[] = [
  {
    name: 'Belastingdienst',
    description: 'Gegevens over uw huishouden, inkomen, bezittingen en schulden.',
    imagePath: '/belastingdienst.png',
    id: 0,
  },
  {
    name: 'MijnOverheid',
    description: 'Gegevens uit de Basis Registratie Personen, het Kadaster en het WOZ-register.',
    imagePath: '/mijnoverheid.png',
    id: 1,
  },
  {
    name: 'UWV',
    description: 'Gegevens over uw inkomen uit londienst.',
    imagePath: '/uwv.png',
    id: 2,
  },
  {
    name: 'MijnPensienoverzicht',
    description: 'Gegevens over uw (toekomstige) pensioenen.',
    imagePath: '/mijnpensioenoverzicht.png',
    id: 3,
  },
];
